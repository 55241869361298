<!-- 购物车删除||结算按钮-->
<template>
  <div
    style="background-color: white ;height: 4rem;line-height: 4rem;  width: 100%; display: flex;flex-direction: row;justify-content: space-between;">
    <div style="display: flex;flex-direction: row;padding-left: 0.5rem;">
      <van-checkbox checked-color="#ee0a24" :loading="submitLoading" v-model="checked"
        @change="handleCheckedChang">全选</van-checkbox>
      <div>
        <span style="margin-left: 0.5rem;color: #666;"> 合计</span><span style="color:#D33B2E;font-weight: 600;"> {{ total }}点</span>
      </div>

    </div>
    <div v-if="edit" @click="onDelete" style="background-color:#D33B2E ; width: 7rem; height: 100%; color: white; text-align: center;">
      删除
    </div>
    <div v-else @click="onSubmit" style="background-color:#D33B2E ; width: 7rem; height: 100%; color: white; text-align: center;">
      结算 
    </div>
  </div>


  <!-- <template #tip>
    你的收货地址不支持同城送, <span @click="onClickEditAddress">结算</span>
  </template> -->
  <!-- </van-submit-bar> -->
</template>

<script>
export default {
  name: "myCartFooter",
  props: {
    total: {
      type: Number,
      default: 0,
    },
    carData: {
      type: Array,
      default: () => {
        return {};
      },
    },
    selectData: {
      type: Array,
    },
    type: {
      type: String,
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checked: false,
      submitLoading: false,
    };
  },
  methods: {
    handleCheckedChang(checked) {
      this.$emit("check", checked);
    },
    onClickEditAddress() { },
    async onSubmit() {
      // 购物车结算点击 判断地址是否存在,不存在需要添加地址
      const existAddressFlag = await this.getAddress();
      if(!existAddressFlag){
        return;
      }
      this.submitLoading = true;
      let form = []
      this.carData.forEach(element => {
        if (this.selectData.includes(element.id)) {
          form.push(element)
        }
      });
      if (form.length == 0) {
        this.$Toast('请选择商品')
        return
      }
      // let rulesId
      // let returns = false
      // form.forEach((element, index) => {
      //   if (index == 0) {
      //     rulesId = element.ruleIds
      //   } else {
      //     if (rulesId != element.ruleIds) {
      //       returns = true
      //     }
      //   }
      // });
      // if (returns) {
      //   this.$Toast('配送方式不同的商品不可一起结算')
      //   return
      // }
      const res = await this.$api.checkIsDelivery(form);
      this.$router.push({
        path: "/confirmOrder",
        query: {
          type: 1,
          code: res.data
        },
      });
    },
    onDelete() {
      this.$emit("onDelete");
    },
    change(val) {
      this.checked = false
    },
    /** 获取地址 */
    async getAddress() {
      // 获取用户配置的地址
      const userInfo = localStorage.getItem("userInfo"); // 用户信息
      const userId = userInfo ? JSON.parse(userInfo).id : "";
      const resAddress = await this.$api.getAddress(userId);
      if (resAddress.code != 0) {
        this.$Toast(res.msg);
        return false;
      }
      if (Array.isArray(resAddress.data) && resAddress.data.length > 0) {
        return true;
      } else {
        // 无地址,提示添加地址
        this.addressTag();
        return false;
      }
    },
    /** 地址弹窗 */
    addressTag() {
      this.$Dialog.confirm({
        title: "提示",
        message: "请先设置收货地址",
        confirmButtonText: '去设置'
      }).then(() => {
        this.$router.push("/EditAddress");
      }).catch(() => {
        // on cancel
      });
    },
  },
};
</script>

<style></style>
